var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.establishmentsSettingsData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("settingType")))]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("fixedDayOfTheMonth") + " / " + _vm.$t("subtractNumberOfDaysFromEndOfTheMonth"))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t("settingName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("attendaceWithImageStatus")))]),_c('th',[_vm._v(_vm._s(_vm.$t("settingStartDate")))]),_c('th',[_vm._v(_vm._s(_vm.$t("settingEndDate")))]),_c('th',[_vm._v(_vm._s(_vm.$t("userMakeAction")))]),_c('th',[_vm._v(_vm._s(_vm.$t("settingDateAction")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentsSettings
                  .establishmentsSettingsArchive
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.establishmentsSettingsData),function(setting,index){return _c('tr',{key:setting.settingToken},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(" "+_vm._s(setting.settingDayFromEndMonthStatus ? _vm.$t("subtractNumberOfDaysFromEndOfTheMonth") : _vm.$t("fixedDayOfTheMonth"))+" ")]),_c('td',[_vm._v(" "+_vm._s(setting.settingDayFromEndMonthStatus ? setting.settingDayFromEndMonth : setting.settingDayInMonth)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.setDataMultiLang( _vm.language, setting.settingNameAr, setting.settingNameEn ))+" ")]),_c('td',[_vm._v(" "+_vm._s(setting.attendaceWithImage == true ? _vm.$t("yes") : _vm.$t("no"))+" ")]),_c('td',[_vm._v(_vm._s(setting.settingActiveStart))]),_c('td',[_vm._v(_vm._s(setting.settingActiveEnd))]),_c('td',[_vm._v(" "+_vm._s(_vm.setDataMultiLang( _vm.language, setting.addedByUserNameAr, setting.addedByUserNameEn ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.setDataMultiLang( _vm.language, _vm.timeE2A(setting.settingInitializeDateTime), setting.settingInitializeDateTime ))+" ")]),_c('td',[_vm._v(_vm._s(setting.settingNotes ? setting.settingNotes : "-"))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentsSettings
                  .establishmentsSettingsArchive
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SettingsGeneralDelete",modifiers:{"SettingsGeneralDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setSettingsData(setting)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }